main
{
    background-color: white;
}

body{
    color: #444444 !important;
}

* img {
    user-select: none;
    pointer-events: none;
}

html {
    width: 100%;
    height: 100%;
}

body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
    height: 100%;
    width: 100%;
}

a {
    color: rgb(248, 68, 69);
}

a:hover {
    color: rgb(255, 104, 104);
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Jost", sans-serif;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

body {
    top: 0px !important;
}


/* The Modal (background) */
.modal-giveaway {
    display: flex; /* Hidden by default */
    align-content: center;
    align-items: center;
    position: fixed; /* Stay in place */
    z-index: 200; /* Sit on top */
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.btn-modal {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 10px 28px 11px 28px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px 0 0 0;
    color: #fff;
    background: rgb(248, 68, 69);
    display: flex;
    justify-content: center;
    align-items: center
}


/* Modal Content/Box */
.modal-body {
    max-width: 60%;
    margin: 0 auto;
}

@media (max-width: 1024px) {
    .modal-body {
        max-width: 100%;
    }
}


/* The Close Button */
#close-modal {
    color: white;
    opacity: 1;
    font-size: 45px;
    font-weight: bold;
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 999;
}

#close-modal:hover,
#close-modal:focus {
    text-decoration: none;
    cursor: pointer;
    color: honeydew;
}

.giveaway-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.giveaway-modal {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    width: 100%;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    display: none;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
}

.back-to-top i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background: rgb(248, 68, 69);
    color: #fff;
    transition: all 0.4s;
}

.back-to-top i:hover {
    background: rgb(255, 99, 99);
    color: #fff;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
}

#header.header-scrolled,
#header.header-inner-pages {
    background: rgba(0, 0, 0, 0.9);
}

#header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    max-width: 215px;
}

#header .logo a {
    color: #fff;
}

#header .logo img {
    /* max-height: 40px; */
    width: 360px;
}

#header #google_translate_element {
    position: absolute;
    left: -30px;
    padding-left: 50px;
    padding-top: 5px;
    top: 100px;
    width: 285px;
    height: 60px;
    border-radius: 90px;
    background-color: rgb(255, 255, 255);
}

.goog-te-combo {
    background-color: rgb(230, 230, 230);
    width: 210px;
    height: 25px;
    text-decoration: none;
    padding: 0;
    border: none;
    border-radius: 20px;
}

.goog-te-combo:focus {
    outline: none;
}

#header #google_translate_element-donations {
    position: absolute;
    left: 15px;
    top: 100px;
}

.mobile-only {
    display: none;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-menu > ul {
    display: flex;
}

.nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
    padding: 10px 0 10px 28px;
}

.nav-menu a {
    display: block;
    position: relative;
    color: #fff;
    transition: 0.3s;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
}

.nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
    color: rgb(248, 68, 69);
}

.nav-menu .drop-down ul {
    border-radius: 8px;
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

.nav-menu .drop-down:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.nav-menu .drop-down li {
    min-width: 180px;
    position: relative;
}

.nav-menu .drop-down ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #0c3c53;
}

.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul .active > a,
.nav-menu .drop-down ul li:hover > a {
    color: rgb(248, 68, 69);
}

.nav-menu .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
    top: 0;
    left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
}

.nav-menu .drop-down .drop-down > a {
    padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
    content: "\eaa0";
    font-family: IcoFont;
    position: absolute;
    right: 15px;
}

@media (max-width: 1366px) {

    .fly-to-earth {
        display: none;
    }

    .nav-menu .drop-down .drop-down ul {
        left: -90%;
    }

    .nav-menu .drop-down .drop-down:hover > ul {
        left: -100%;
    }

    .nav-menu .drop-down .drop-down > a:after {
        content: "\ea9d";
    }
}

/* Get Startet Button */
.get-started-btn {
    margin-left: 25px;
    color: #fff;
    border-radius: 50px;
    padding: 6px 25px 7px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
    border: 2px solid rgb(248, 68, 69);
    font-weight: 600;
}

.get-started-btn:hover {
    background: rgb(248, 68, 69);
    color: #fff;
}

@media (max-width: 768px) {
    .get-started-btn {
        margin: 0 48px 0 0;
        padding: 6px 20px 7px 20px;
    }
}

@media screen and (max-width: 376px) {

}

/* Mobile Navigation */
.mobile-nav-toggle {
    position: fixed;
    top: 20px;
    right: 15px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
}

.mobile-nav-toggle i {
    color: #fff;
}

.mobile-nav {
    position: fixed;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    z-index: 9999;
    overflow-y: auto;
    background: #fff;
    transition: ease-in-out 0.2s;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    padding: 10px 0;
}

.mobile-nav * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.mobile-nav a {
    display: block;
    position: relative;
    color: rgb(206, 55, 55);
    padding: 10px 20px;
    font-weight: 500;
    outline: none;
}

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
    color: rgb(248, 68, 69);
    text-decoration: none;
}

.mobile-nav .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 10px;
    position: absolute;
    right: 15px;
}

.mobile-nav .active.drop-down > a:after {
    content: "\eaa1";
}

.mobile-nav .drop-down > a {
    padding-right: 35px;
}

.mobile-nav .drop-down ul {
    display: none;
    overflow: hidden;
}

.mobile-nav .drop-down li {
    padding-left: 20px;
}

.mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(40, 58, 90, 0.6);
    overflow: hidden;
    display: none;
    transition: ease-in-out 0.2s;
}

.mobile-nav-active {
    overflow: hidden;
}

.mobile-nav-active .mobile-nav {
    opacity: 1;
    visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
    color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 80vh;

    background-color: var(--gray10);
    background-image: radial-gradient(
            circle,
            #bd0000,
            #990000,
            #720000,
            #520000,
            #360000
    );
    position: relative;
}

#hero .container {
    padding-top: 0;
    margin-top: 20px;
    /* position: relative; */
}

#hero h1 {
    margin: 0 0 10px 0;
    font-size: 38px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
}

#hero h2 {
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 50px;
    font-size: 24px;
}

#hero .btn-get-started {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 11px 28px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px 0 0 0;
    color: #fff;
    background: rgb(248, 68, 69);
}

#hero .btn-get-started:hover {
    box-shadow: 0 0 10px #ff5e5e;
}

#hero .btn-watch-video {
    font-size: 16px;
    display: inline-block;
    padding: 10px 0 8px 40px;
    transition: 0.5s;
    margin: 10px 0 0 25px;
    color: #fff;
    position: relative;
}

#hero .btn-watch-video i {
    color: #fff;
    font-size: 32px;
    position: absolute;
    left: 0;
    top: 7px;
    transition: 0.3s;
}

#hero .btn-watch-video:hover i {
    color: rgb(248, 68, 69);
}

#hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

#hero .logo-container {
    margin: 6vh 0 0 0;
}

#hero .news-element {
    align-items: center;
}

#hero .logo-container a:hover {
    transition: 0.1s ease;
    transform: scale(1.05);
}

.header-news {
    background-color: var(--gray10);
    background-image: radial-gradient(circle, #bd0000, #990000, #720000, #520000, #360000);
}

.iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
}

#iframe-ama {
    height: 836px;
    margin: 0 auto 50px;
    border: 0;
}

section#cliens a {
    display: inline;
    /* text-align: center; */
    padding-left: 0px;
    background-color: rgb(248, 68, 69);
    border-radius: 25px;
    color: white;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 11px;
    cursor: pointer;
}

section#cliens p {
    padding-left: 0px;
    font-size: 12px;
}

section#cliens a {
    padding-right: 5px;
    padding-left: 5px;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.news-element img {
    width: 142px;
    height: 35px;
    margin: 0 auto;
}

@media (max-width: 991px) {
    #hero {
        height: 100%;
        text-align: center;
    }

    #hero .container {
        margin-top: 25px;
    }

    #hero .animated {
        -webkit-animation: none;
        animation: none;
    }

    #hero .hero-img {
        text-align: center;
    }

    #hero .hero-img img {
        width: 50%;
    }

    .mobile-only {
        display: inline;
    }
}

@media (max-width: 768px) {
    .mars-img-header {
        display: none;
    }

    #hero .center-mobile {
        justify-content: center;
    }

    small.mt-4.text-center {
        width: 100%;
        padding: 0px 15px;
    }

    section#services .section-title p {
        padding: 20px;
    }

    .portfolio #portfolio-flters {
        display: block !important;
    }

    .services .icon-box {
        width: 100%;
    }

    .item.completed.section-title i,
    .item.soon.section-title i,
    .item.progress-road.section-title i {
        font-size: 42px;
        color: green;
        position: absolute;
        top: 6px;
        right: 13% !important;
    }

    .time-line-carousel .item,
    .owl-item.active .completed,
    .owl-item.active .progress-road,
    .owl-item .progress-road,
    .owl-item.active .soon {
        max-width: 80%;
        margin: 0 auto;
    }

    #hero .btn-get-started.token-add {
        font-size: 14px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
        pointer-events: none;
        display: none;
    }

    #hero .logo-container {
        position: static;
    }

    #hero .d-lg-flex {
        display: inherit;
    }

    #hero .btn-get-started {
        display: inline;
    }

    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    #hero .hero-img img {
        width: 70%;
    }


    #hero iframe {
        width: 100% !important;
        height: 100% !important;
    }

    section#cliens h3,
    section#cliens p {
        text-align: center;
    }

    .section-title-hero.text-right h2 {
        text-align: center;
        color: rgb(248, 68, 69);
    }

    .gif-wrapper {
        margin-top: 0px;
    }
}

@media (max-width: 575px) {
    #header #google_translate_element {
        position: absolute;
        background: white;
        width: 100%;
        left: 0;
        height: 100%;
        top: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
        padding-left: 0px;
    }

    .goog-te-combo {
        background-color: rgb(230, 230, 230);
        width: 205px;
        height: 25px;
        text-decoration: none;
        padding: 0;
        border: none;
        border-radius: 20px;
    }

    #hero .container {
        margin-top: 100px;
    }

    #header #google_translate_element-donations {
        position: absolute;
        background: white;
        width: 100%;
        left: 0;
        height: 100%;
        top: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .item.completed.section-title i,
    .item.soon.section-title i,
    .item.progress-road.section-title i {
        font-size: 42px;
        color: green;
        position: absolute;
        top: 6px;
        right: 13%;
    }

    .time-line-carousel .item,
    .owl-item.active .completed,
    .owl-item.active .progress-road,
    .owl-item .progress-road,
    .owl-item.active .soon {
        max-width: 80%;
        margin: 0 auto;
    }

    #hero .btn-get-started.token-add {
        font-size: 14px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
        pointer-events: none;
        display: none;
    }

    #hero .logo-container a {
        padding: 20px;
    }

    #hero .news-element {
        margin-right: 0;
    }

    #hero .d-lg-flex {
        display: inherit;
    }

    #hero .btn-get-started {
        display: inline;
    }

    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    #hero .hero-img img {
        width: 70%;
    }

    img.mars-img-header {
        display: none;
    }

    #hero iframe {
        width: 100% !important;
        height: 100% !important;
    }

    section#cliens h3,
    section#cliens p {
        text-align: center;
    }

    .section-title-hero.text-right h2 {
        text-align: center;
    }

    .gif-wrapper {
        margin-top: 0px;
    }

    #hero .hero-img img {
        width: 80%;
    }

    #hero .btn-get-started {
        font-size: 16px;
        padding: 10px 24px 11px 24px;
    }

    #hero .btn-watch-video {
        font-size: 16px;
        padding: 10px 0 8px 40px;
        margin-left: 20px;
    }

    #hero .btn-watch-video i {
        font-size: 32px;
        top: 7px;
    }
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg {
    background-color: #1a1a1a;
    /* color: white; */
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: rgb(248, 68, 69);
}

.section-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
}

.section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: rgb(248, 68, 69);
    bottom: 0;
    left: calc(50% - 20px);
}

.section-title p {
    margin-bottom: 0;
    color: #f8f9fa;
}

.section-title .black {
    color: #1a1a1a;
}

/*--------------------------------------------------------------
# Cliens
--------------------------------------------------------------*/
.cliens {
    padding: 39px 0;
    text-align: center;
}

.cliens img {
    max-width: 45%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 15px 0;
    filter: grayscale(100);
}

.cliens img:hover {
    filter: none;
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .cliens img {
        max-width: 40%;
    }
}

/*--------------------------------------------------------------
# Chart
--------------------------------------------------------------*/

.chart {
    width: 100%;
    height: calc(100% - 80vh);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: var(--fonts);
}

.chart h1 {
    margin-top: 50px;
    margin-bottom: 90px;
    font-size: 45px;
    text-transform: uppercase;
    font-style: italic;
    padding: 0px 30px 5px 20px;
    border-bottom: solid 4px white;
    border-radius: 65px 15px;
    font-weight: 700;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chart .live {
    width: 57%;
    height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chart .records .part {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.chart .records .part a {
    margin-top: -15px;
}

.chart .records .part a button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: none;
    color: rgb(255, 255, 255);
    border: none;
    font-size: 15px;
    font-weight: 800;
    width: 170px;
    height: 40px;
    text-transform: uppercase;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
}

.chart .live .part a button:hover {
    background: none;
    color: white;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    box-shadow: 0 0 10px #ff5e5e;
    border-radius: 70px;
}

.chart .live .part .cur::before {
    content: "$";
    margin-right: 4px;
}

.chart .live .part .po {
    color: #00be00;
}

.chart .live .part .po::before {
    content: "+";
    margin-right: 4px;
}

.chart .live .part .po::after {
    content: "%";
    margin-left: 4px;
}

.chart .live .part .ne {
    color: red;
}

.chart .live .part .ne::before {
    content: "-";
    margin-right: 4px;
}

.chart .live .part .ne::after {
    content: "%";
    margin-left: 4px;
}

.chart .live .part h3 {
    font-size: 19px;
}

.chart .live .part .mo:before {
    content: "$";
    margin-right: 4px;
}

.chart .live .part p {
    text-transform: uppercase;
    margin-top: 5px;
    font-size: 13px;
    opacity: 0.5;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}

.chart .records {
    margin-top: 20px;
    width: 1000px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.chart .records .part {
    height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.chart .records .part img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    height: 60px;
    margin-bottom: 20px;
}

.chart .records .part h3 {
    font-size: 20px;
    color: white;
}

.chart .records .part p {
    text-transform: uppercase;
    margin-top: 5px;
    font-size: 13px;
    opacity: 0.5;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    color: rgba(255, 255, 255, 0.733);
}

.chart .records .part .marketcap::before {
    content: "$";
    margin-right: 4px;
}

.chart .records .part .liquid::before {
    content: "$";
    margin-right: 4px;
}

#iframe-swap {
    width: 50%;
    height: 836px;
    margin: 0 auto 50px;
    border: 0;
}

@media (max-width: 1024px) {
    .chart .records {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .chart {
        height: 100%;
    }

    #iframe-swap {
        width: 100%;
        height: 836px;
        margin: 0 auto 50px;
        border: 0;
    }

    .chart .records {
        flex-direction: column-reverse;
        width: 100%;
        /*margin-bottom: -100px;*/
    }

    .chart .records .part {
        /*height: 200px;*/
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 5px;
    }
}

@media (max-width: 575px) {
    .why-us .img {
        min-height: 200px;
    }
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-left: 28px;
    position: relative;
}

.about .content ul li + li {
    margin-top: 10px;
}

.about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: rgb(248, 68, 69);
    line-height: 1;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about .content .btn-learn-more {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 4px;
    transition: 0.3s;
    line-height: 1;
    color: rgb(248, 68, 69);
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid rgb(248, 68, 69);
}

.about .content .btn-learn-more:hover {
    background: rgb(248, 68, 69);
    color: #fff;
    text-decoration: none;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .content {
    padding: 60px 100px 0 100px;
}

.why-us .content h3 {
    font-weight: 400;
    font-size: 34px;
    color: rgb(206, 55, 55);
}

.why-us .content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
}

.why-us .content p {
    font-size: 15px;
    color: #f8f9fa;
}

.why-us .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.why-us .accordion-list {
    padding: 0 100px 60px 100px;
}

.why-us .accordion-list ul {
    padding: 0;
    list-style: none;
}

.why-us .accordion-list li + li {
    margin-top: 15px;
}

.why-us .accordion-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
}

.why-us .accordion-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 30px;
    outline: none;
}

.why-us .accordion-list span {
    color: rgb(248, 68, 69);
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
}

.why-us .accordion-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
}

.why-us .accordion-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
    display: none;
}

.why-us .accordion-list a.collapsed {
    color: #343a40;
}

.why-us .accordion-list a.collapsed:hover {
    color: rgb(248, 68, 69);
}

.why-us .accordion-list a.collapsed .icon-show {
    display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
    display: none;
}

@media (max-width: 1024px) {
    .why-us .content,
    .why-us .accordion-list {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 992px) {
    .why-us .img {
        min-height: 400px;
    }

    .why-us .content {
        padding-top: 30px;
    }

    .why-us .accordion-list {
        padding-bottom: 30px;
    }
}

@media (max-width: 575px) {
    .why-us .img {
        min-height: 200px;
    }
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills .content h3 {
    font-weight: 700;
    font-size: 32px;
    color: rgb(206, 55, 55);
    font-family: "Poppins", sans-serif;
}

.skills .content ul {
    list-style: none;
    padding: 0;
}

.skills .content ul li {
    padding-bottom: 10px;
}


section#hero video {
    max-width: 539px;
    width: 100%;
    height: auto;
}


.skills .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: rgb(248, 68, 69);
}

.skills .content p:last-child {
    margin-bottom: 0;
}

.skills .progress {
    height: 50px;
    display: block;
    background: none;
}

.skills .progress .skill {
    padding: 10px 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: rgb(206, 55, 55);
}

.skills .progress .skill .val {
    float: right;
    font-style: normal;
}

.skills .progress-bar-wrap {
    background: #e8edf5;
}

.skills .progress-bar {
    width: 1px;
    height: 10px;
    transition: 0.9s;
    background-color: #4668a2;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 60px 20px 60px 20px;
    transition: all ease-in-out 0.4s;
    background: #fff;
}

.services .container .row {
    display: flex;
    justify-content: center;
}

.services .icon-box .icon {
    margin-bottom: 10px;
}

.services .icon-box .icon i {
    color: rgb(248, 68, 69);
    font-size: 36px;
    transition: 0.3s;
    display: inline;
}

.services .icon-box h4 {
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 48px;
    display: inline;
    color: rgb(248, 68, 69);
}

.services .icon-box h4 a {
    color: rgb(172, 44, 44);
    transition: ease-in-out 0.3s;
}

.services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.services .icon-box:hover {
    transform: translateY(-10px);
}

.services .icon-box:hover h4 a {
    color: rgb(248, 68, 69);
}

.services small {
    color: rgba(255, 255, 255, 0.486);
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    background: linear-gradient(rgba(90, 0, 0, 0.7), rgba(90, 0, 15, 0.7)),
    url(../img/cta-bg.png) fixed center center;
    background-size: cover;
    padding: 120px 0;
    background-position-y: -50px;
}

.cta h3 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
}

.cta p {
    color: #fff;
}

.cta .cta-btn {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #fff;
    color: #fff;
}

.cta .cta-btn:hover {
    background: rgb(248, 68, 69);
    border: 2px solid rgb(248, 68, 69);
}

@media (max-width: 1024px) {
    .cta {
        background-attachment: scroll;
    }
}

@media (min-width: 769px) {
    .cta .cta-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    #hero .logo-container {
        margin: 8vh 0 0 0;
    }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
    list-style: none;
    margin-bottom: 20px;
}

.portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    margin: 10px 5px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    color: rgb(206, 55, 55);
    transition: all 0.3s;
    padding: 8px 20px;
    border-radius: 50px;
    font-family: "Poppins", sans-serif;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
    background: rgb(248, 68, 69);
    color: #fff;
}

.portfolio .portfolio-item {
    margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-img {
    overflow: hidden;
}

.portfolio .portfolio-item .portfolio-img img {
    transition: all 0.6s;
}

.portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 15px;
    bottom: 0;
    z-index: 3;
    right: 15px;
    transition: all 0.3s;
    background: rgba(55, 81, 126, 0.8);
    padding: 10px 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
}

.portfolio .portfolio-item .portfolio-info p {
    color: #f9fcfe;
    font-size: 14px;
    margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #fff;
    transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: rgb(248, 68, 69);
}

.portfolio .portfolio-item .portfolio-info .details-link {
    right: 10px;
}

.portfolio .portfolio-item:hover .portfolio-img img {
    transform: scale(1.15);
}

.portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
    position: relative;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 5px;
    background: #fff;
    transition: 0.5s;
}

.team .member .pic {
    overflow: hidden;
    width: 180px;
    border-radius: 50%;
    user-select: none;
    pointer-events: none;
}

.team .member .pic img {
    transition: ease-in-out 0.3s;
}

.team .member .dox {
    filter: grayscale(100%);
}

.team .member:hover {
    transform: translateY(-10px);
}

.team .member .member-info {
    padding-left: 30px;
}

.team .member h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: rgb(170, 0, 0);
}

.team .member span {
    display: block;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
}

.team .member span::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #cbd6e9;
    bottom: 0;
    left: 0;
}

.team .member p {
    margin: 10px 0 0 0;
    font-size: 14px;
}

.team .member .social {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.team .member .social a {
    transition: ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    background: #eff2f8;
}

.team .member .social a i {
    color: rgb(170, 0, 0);
    font-size: 16px;
    margin: 0 2px;
}

.team .member .social a:hover {
    background: rgb(248, 68, 69);
}

.team .member .social a:hover i {
    color: #fff;
}

.team .member .social a + a {
    margin-left: 8px;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
    padding-top: 40px;
}

.pricing .box {
    padding: 60px 40px;
    box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
    background: #fff;
    height: 100%;
    border-top: 4px solid #fff;
    border-radius: 5px;
}

.pricing h3 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 20px;
    color: rgb(206, 55, 55);
}

.pricing h4 {
    font-size: 48px;
    color: rgb(206, 55, 55);
    font-weight: 400;
    font-family: "Jost", sans-serif;
    margin-bottom: 25px;
}

.pricing h4 sup {
    font-size: 28px;
}

.pricing h4 span {
    color: rgb(248, 68, 69);
    font-size: 18px;
    display: block;
}

.pricing ul {
    padding: 20px 0;
    list-style: none;
    color: #999;
    text-align: left;
    line-height: 20px;
}

.pricing ul li {
    padding: 10px 0 10px 30px;
    position: relative;
}

.pricing ul i {
    color: #28a745;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 6px;
}

.pricing ul .na {
    color: #ccc;
}

.pricing ul .na i {
    color: #ccc;
}

.pricing ul .na span {
    text-decoration: line-through;
}

.pricing .buy-btn {
    display: inline-block;
    padding: 12px 35px;
    border-radius: 50px;
    color: rgb(248, 68, 69);
    transition: none;
    font-size: 16px;
    font-weight: 500;
    font-family: "Jost", sans-serif;
    transition: 0.3s;
    border: 1px solid rgb(248, 68, 69);
}

.pricing .buy-btn:hover {
    background: rgb(248, 68, 69);
    color: #fff;
}

.pricing .featured {
    border-top-color: rgb(248, 68, 69);
}

.pricing .featured .buy-btn {
    background: rgb(248, 68, 69);
    color: #fff;
}

.pricing .featured .buy-btn:hover {
    background: rgb(248, 68, 69);
}

@media (max-width: 992px) {
    .pricing .box {
        max-width: 60%;
        margin: 0 auto 30px auto;
    }
}

@media (max-width: 767px) {
    .pricing .box {
        max-width: 80%;
        margin: 0 auto 30px auto;
    }
}

@media (max-width: 420px) {
    .pricing .box {
        max-width: 100%;
        margin: 0 auto 30px auto;
    }
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
    padding: 0 100px;
}

.faq .faq-list ul {
    padding: 0;
    list-style: none;
}

.faq .faq-list li + li {
    margin-top: 15px;
}

.faq .faq-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
}

.faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
}

.faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: rgb(248, 68, 69);
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
}

.faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
    display: none;
}

.faq .faq-list a.collapsed {
    color: rgb(170, 0, 0);
    transition: 0.3s;
}

.faq .faq-list a.collapsed:hover {
    color: rgb(248, 68, 69);
}

.faq .faq-list a.collapsed .icon-show {
    display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
    display: none;
}

@media (max-width: 1200px) {
    .faq .faq-list {
        padding: 0;
    }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
    border-top: 3px solid rgb(248, 68, 69);
    border-bottom: 3px solid rgb(248, 68, 69);
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.contact .info i {
    font-size: 20px;
    color: rgb(248, 68, 69);
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: rgb(206, 55, 55);
}

.contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #6182ba;
}

.contact .info .email p {
    padding-top: 5px;
}

.contact .info .social-links {
    padding-left: 60px;
}

.contact .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
}

.contact .info .social-links a:hover {
    background: rgb(248, 68, 69);
    color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
    background: rgb(248, 68, 69);
    color: #fff;
}

.contact .php-email-form {
    width: 100%;
    border-top: 3px solid rgb(248, 68, 69);
    border-bottom: 3px solid rgb(248, 68, 69);
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

.contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br + br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: rgb(248, 68, 69);
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
    background: rgb(248, 68, 69);
    border: 0;
    padding: 12px 34px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
    background: #209dd8;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background: #f3f5fa;
    min-height: 40px;
    margin-top: 108px;
}

@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 68px;
    }
}

.breadcrumbs h2 {
    font-size: 28px;
    font-weight: 600;
    color: rgb(206, 55, 55);
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
}

.breadcrumbs ol li + li {
    padding-left: 10px;
}

.breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #4668a2;
    content: "/";
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;
}

.portfolio-details .portfolio-details-container {
    position: relative;
}

.portfolio-details .portfolio-details-carousel {
    position: relative;
    z-index: 1;
}

.portfolio-details .portfolio-details-carousel .owl-nav,
.portfolio-details .portfolio-details-carousel .owl-dots {
    margin-top: 5px;
    text-align: left;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
    display: inline-block;
    margin: 0 10px 0 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
    background-color: rgb(248, 68, 69) !important;
}

.portfolio-details .portfolio-info {
    padding: 30px;
    position: absolute;
    right: -50px;
    bottom: -200px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.portfolio-details .portfolio-title {
    width: 100%;
    margin-top: 30px;
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
    margin-top: 10px;
}

.portfolio-details .portfolio-description {
    padding-top: 50px;
}

.portfolio-details .portfolio-description h2 {
    width: 50%;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
    padding: 0 0 0 0;
}

@media (max-width: 768px) {
    .portfolio-details .portfolio-description h2 {
        width: 100%;
    }

    .portfolio-details .portfolio-info {
        position: static;
        margin-top: 30px;
    }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    font-size: 14px;
    background: rgba(0, 0, 0, 0.9);
}

#footer .footer-newsletter {
    padding: 50px 0;
    background: #f3f5fa;
    text-align: center;
    font-size: 15px;
    color: rgb(206, 55, 55);
}

#footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    color: rgb(248, 68, 69);
}

#footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
    text-align: left;
}

#footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: rgb(248, 68, 69);
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
    background: #209dd8;
}

#footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

.time-line-carousel .owl-nav {
    text-align: right;
}

.time-line-carousel .item,
.owl-item.active .completed,
.owl-item.active .progress-road,
.owl-item .progress-road,
.owl-item.active .soon {
    min-height: 200px;
    padding: 23px;
    text-align: center;
    border: 2px solid rgb(206, 55, 55);
}

.item.completed h2,
.owl-item.active .completed,
.owl-item.active .progress-road,
.owl-item.active .soon {
    color: rgb(206, 55, 55);
}

.item.completed {
    opacity: 0.5;
}

.item.completed.section-title i {
    font-size: 42px;
    color: green;
    position: absolute;
    top: 6px;
    right: 13px;
}

.item.progress-road.section-title i {
    font-size: 42px;
    color: orange;
    position: absolute;
    top: 6px;
    right: 13px;
}

.item.soon.section-title i {
    font-size: 42px;
    color: orange;
    position: absolute;
    top: 6px;
    right: 13px;
}

#footer .footer-top .footer-contact h3 {
    font-size: 28px;
    margin: 0 0 10px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
    color: rgb(206, 55, 55);
}

#footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Jost", sans-serif;
    color: #5e5e5e;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: rgb(206, 55, 55);
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #e46c47;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: rgb(248, 68, 69);
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: rgb(248, 68, 69);
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: rgb(248, 68, 69);
    color: #fff;
    text-decoration: none;
}

#footer .footer-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    color: #fff;
}

#footer .copyright {
    float: left;
}

#footer .credits {
    float: right;
    font-size: 13px;
}

#footer .credits a {
    transition: 0.3s;
}

@media (max-width: 768px) {
    #footer .footer-bottom {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    #footer .copyright,
    #footer .credits {
        text-align: center;
        float: none;
    }

    #footer .credits {
        padding-top: 4px;
    }
}

h2.counter-count.xl-font {
    font-size: 70px;
}

.main-break span::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
}

.main-break span::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: rgb(248, 68, 69);
    bottom: 0;
    left: calc(50% - 20px);
}

.section-title h3 {
    position: relative;
    color: rgb(206, 55, 55);
}

.gif-wrapper {
    margin-top: 100px;
}

.max-w {
    width: 100%;
}

.footer-newsletter .bx {
    font-size: 30px;
    padding-right: 15px;
}

.col-lg-12.col-md-6.text-center.mt-5.portfolio-item.filter-oceans.filter-forest.filter-people.filter-food.filter-water.filter-other
h4 {
    color: rgb(206, 55, 55);
}

.section-title-hero h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0px;
    position: relative;
    color: rgb(248, 68, 69);
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    font-size: 37px;
    color: rgb(206, 55, 55);
}

.timeline ul li {
    position: relative;
    list-style-type: none;
    width: 6px;
    background-color: white;
    margin: 0 auto;
    padding-top: 50px;
}

.timeline ul li::after {
    background: white;
    content: "";
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

.timeline ul li div {
    position: relative;
    width: 400px;
    padding: 15px;
    border-radius: 5px;
    bottom: 0;
    background: rgba(42, 50, 113, 0.28);
}

.timeline ul li:nth-child(odd) div {
    left: 45px;
}

.timeline ul li:nth-child(even) div {
    left: -445px;
}

.timeline time {
    font-weight: bold;
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
}

.timeline ul li div {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.timeline ul li:nth-child(odd) div {
    transform: translate3d(200px, 0, 0);
}

.timeline ul li:nth-child(even) div {
    transform: translate3d(-200px, 0, 0);
}

.timeline ul li.show div {
    transform: none;
    visibility: visible;
    opacity: 1;
    /* z-index: 9999; */
    background-color: white;
}

@media screen and (max-width: 900px) {
    .timeline ul li div {
        width: 250px;
    }

    .timeline ul li:nth-child(even) div {
        left: -289px;
        /*250+45-6*/
    }
}

.bg-time {
    background: rgba(0, 14, 90, 0.9);
}

.row.timeline.no-gutters > div {
    margin-left: 50%;
}

.fly-to-earth {
    width: 70px;
    height: 70px;
    position: absolute;
    right: 30px;
}

figure.bounce {
    position: absolute;
    left: 48%;
    bottom: 0;
    transform: translate(-48%, -4%);
}

.bounce {
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
}

@-moz-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

#hero .btn-get-started.token-add {
    font-size: 14px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
    pointer-events: none;
}

.max-w-80 {
    max-width: 80%;
    margin: 0 auto;
}

img.mars-img-header {
    width: 70px;
    height: 70px;
    position: absolute;
    right: 30px;
}

#hero a.btn-get-started.no-bg-btn.scrollto {
    margin-left: 20px;
    background: none;
    border: 2px solid rgb(248, 68, 69);
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
}

#hero a.btn-get-started.no-bg-btn.scrollto:hover {
    background: rgb(248, 68, 69);
    color: #fff;
}
section#latest-news video {
    max-width: 100%;
    height: 100%;
}

a
{
    text-decoration : none !important;
}
section#game-alpha a {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 11px 28px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px 0 0 0;
    color: #fff;
    background: rgb(248, 68, 69);
    margin: 0 auto;
    text-align: center;
    text-decoration : none;
}

ul.test-dots p {
    display: inline;
}

ul.test-dots {
    list-style: none;
    margin-left: 0px;
    padding-left: 20px;
    text-align: left;
}

 

section#game-alpha video {
    max-width: 78%;
    margin: 0 auto;
    text-align: center;
}

ul.test-dots li {
    margin-bottom: 25px;
}

ul.test-dots p {
    font-family: "Open Sans", sans-serif;
    color: #444444;
    font-size: 20px;
}

ul.test-dots li:before {
    font-size: 28px;
    color: green;
}

section#skills .col-lg-6 {
    padding: 29px;
}

small.mt-4.text-center {
    width: 100%;
}

.row.main-break.aos-init.aos-animate h3 {
    margin-bottom: 0px;
    margin-top: -21px;
}

.margin-center
{
    margin : 0 auto;
    padding-bottom: 10px;
}

.slick-slide
{
    padding-left: 5px;
    padding-right: 5px;
}

.ant-layout
{
    padding-top: 0px;
}

section#game-alpha a
{
    width: auto;
}


.bx {
    font-family: 'boxicons'!important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    display: inline-block;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button.owl-next, button.owl-prev, button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    font-size: 37px;
    color: rgb(206, 55, 55);
}

.owl-nav
{
    text-align: right;
}

.safemars_normal_font
{
    font-weight: 500;
}

