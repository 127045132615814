


section.hero {
   background: url(../img/Astrotesla_019.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover  !important;
}


.stas-home {
	//margin-left:  38%;
	margin-top:  30%;
}

.max-w-80 {
    max-width: 49%;
    margin: 0 auto;
}


.hero h1 {
    padding-top: 5%;
}

.bg-body-safemars  {
 
    background: radial-gradient(174.47% 188.91% at 1.84% 0%, rgb(221, 24, 24) 0%, rgb(51, 51, 51) 100%), rgb(237, 238, 242) !important;
}

.item-bg {
    background: url(../img/Astrotesla_019.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover  !important;
}

.hcenter
{
    text-align: center;
}

.header
{
    background-color: red;
}

.nav-item{
    order: unset !important;
}

.section_margin
{
    padding-top: 30px;
    padding-bottom : 30px;
}

.ind_nft_header{
    color: white;
}

.ant-menu, .ant-layout, .ant-card
{
    background-color: transparent;
}

.ant-card-bordered
{
    border: none;
}

.ant-card, .ant-card-meta-title, .ant-card-meta-description
{
    color : white;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu, 
.ant-menu-item, .ant-menu-submenu-title
{
    padding : 0px !important;
}

.ant-modal-body
{
    text-align: center;
}

.slick-slider
{
    padding : 0px;
}

.sliderFilter
{
    margin-left: 60px;
}

.sliderFilter .ant-select-selector
{
    background-color: #131722 !important;
    padding: 0.625rem 0.75rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
       color: #78828a;
    background-color: #171c28;
    background-clip: padding-box;
    border: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height : auto !important;
}

.sliderFilter .ant-select-selection-item
{
     color: #78828a;
}

.ant-select-dropdown, .ant-select-item-option-selected:not(.ant-select-item-option-disabled), .ant-select-item
{
        background: rgba(27, 33, 46, 0.9) !important;
    border-color: rgb(30, 37, 53) !important;
    color : #dee2e6;
    font-weight : normal;
}

.no-left-margin-filter
{
    margin-left: 8px;
}


.ant-menu-item
{
    padding: 5px 15px !important;
    border-bottom: 1px solid gray;
}

.singleItemHeader
{
    color:  #dee2e6;
    font-size: 38px;
}

.ant-menu, .nav-item
{
    border-bottom: none;
}

.nav-item:after
{
    border-bottom: nonw;
}

.breadcrumb
{
    background-color: transparent;
}

#dropdownMenuButton
{
    background-color: transparent;
    border: none !important;
}

.dropdown-toggle::after
{
    display: none;
}

.white_color
{
    color : #dee2e6
}

.no-padding-bottom
{
    padding-bottom: 0px;
}

.single_buy_button
{
    min-width: 90px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button 
{
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.ant-spin-dot-item
{
    background-color : white;
}

.file_design{
      width: 100%;
    border: 2px solid #1d2231;
    background-color: #171c28;
    font-size: 1rem;
    padding: 0.125rem 0.15rem;
    border-radius: 0.25rem;
 
}

.badge_col_safemars .ant-ribbon
{
    position: static;
    background: transparent;
    height: auto;
   white-space: initial;
   border-radius: 0.25rem;
   text-align: right;
   padding-bottom: 10px;
}

.badge_col_safemars .ant-ribbon, .badge_col_safemars .ant-ribbon-text
{
     padding:  5px;
         padding-top: 4px;
    padding-bottom: 4px;
}

.badge_col_safemars .ant-ribbon-text
{
    padding-right: 0px;
}

.badge_col_safemars .ant-ribbon-corner, .marketplace_price .ant-ribbon-corner
{
    display: none;
}

.nft_cards .buy_now_button, .nft_cards .ant-ribbon-text
{
    font-size: 12px;
    padding-left:  5px;
    padding-right : 5px;
}

.marketplace_price .ant-ribbon
{
    background-color: transparent;
}

.ant-layout
{
    padding-bottom: 0px;
}

.header_dropdown .dropdown-item
{
        padding-top: 0px;
    padding-bottom: 0px;
}

.min_height_price
{
    min-height: 30px;
}

.slick-track
{
    margin : 0 auto;
}

.white-color
{
    color : #e9ecef
}

.stas-home {
    margin: 0 auto;
    margin-top: 30%;
    max-width: 400px;
}